import React, {useCallback, useContext, useEffect, useState} from "react";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Collapse from '@mui/material/Collapse';
import {colors} from "../config/theme";
import Typography from "@mui/material/Typography";
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import {useTheme} from '@mui/material/styles';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import IconButton from "@mui/material/IconButton";
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import {api, FirebaseContext, store} from "common";
import {useDispatch, useSelector} from "react-redux";
import {ConverTimeStampToHHMM, ConvertTimeStampPaUnique, ConvertTimeStampTimeForPaShort, ConvertTimeStampToDate} from "common/src/other/DateFunctions";
import {Text, TouchableOpacity, View} from "react-native-web";
import ListItemText from '@mui/material/ListItemText';
import * as geolib from "geolib";
import LinearProgress from "@mui/material/LinearProgress";
import CircularProgress from "@mui/material/CircularProgress";
import DirectionsIcon from "@mui/icons-material/Directions";
import {Dimensions} from "react-native";
import TableHead from "@mui/material/TableHead";
import {CALL_DETAIL_DATA, FETCH_BOOKING_BY_ID_SUCCESS, FETCH_BOOKING_TRACKER_SUCCESS, LIST_USER_LOST_CONNECT, UPDATE_ZOOM_MAP} from "common/src/store/types";
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import RemoveCircleOutlineRoundedIcon from '@mui/icons-material/RemoveCircleOutlineRounded';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import BookingLaterSnackbarComponent from "./Dashboard/BookingLaterSnackbarComponent";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import {language} from "config";
import {RequestPushMsg} from "common/src/other/NotificationFunctions";
import * as Speech from 'expo-speech';
import {HiddenPhone} from "common/src/other/CommonFunctions";
import LockIcon from '@mui/icons-material/Lock';
import NotifyCenterSnackbarComponent from "./Dashboard/NotifyCenterSnackbarComponent";

var {height, width} = Dimensions.get('window');

function TabPanel(props) {
    const {children, value, index, ...other} = props;
    return (<div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
    >
        {value === index && (<Box sx={{p: 3}}>
            <Typography>{children}</Typography>
        </Box>)}
    </div>);
}

TabPanel.propTypes = {
    children: PropTypes.node, index: PropTypes.number.isRequired, value: PropTypes.number.isRequired,
};

function createData(name, code, population, size) {
    const density = population / size;
    return {name, code, population, size, density};
}

export default function RightDashboardComponent(props) {
    const {refApi} = useContext(FirebaseContext);
    const {
        getDriversCenter
    } = api;
    const dispatch = useDispatch();
    const theme = useTheme();
    const value = useSelector(state => state.sessiondata.tabIndexRight);
    const auth = useSelector(state => state.auth.user);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page2, setPage2] = useState(0);
    const [rowsPerPage2, setRowsPerPage2] = useState(10);
    const cars = useSelector(state => state.cartypes.cars);
    const userdata_loading = useSelector(state => state.usersdata.loading);
    const group_kd = useSelector(state => state.groupdriverdata.group_kd);
    const [rows, setRows] = useState([]);
    const [filterDriver, setFilterDriver] = useState(null);
    const [codeDriverSelect, setCodeDriverSelect] = useState(null);
    const pacallcenterdatas = useSelector((state) => state.pacallcenterdata);
    const [listCall, setListCall] = useState([]);
    const users = useSelector(state => state.usersdata.get_drivers);
    const [intervalIdStore, setIntervalIdStore] = useState(null);
    const [filterCallType, setFilterCallType] = useState({type: 'chuaxuly'});
    const reportdriverdata = useSelector(state => state.reportdriverdata);
    const callDetailData = useSelector(
        (state) => state.pacallcenterdata.callCenter
    );
    //const bookingDataById = useSelector((state) => state.bookinglistdata.booking_active);
    const [dataRp, setDataRp] = useState([]);
    const [dataRpImportance, setDataRpImportance] = useState([]);
    const [row, setRow] = useState(null);
    const callDetail = useSelector(state => state.sessiondata.callDetail);
    const [phonePriority, setPhonePriority] = useState(null);
    const [activeRow, setActiveRow] = useState(null);
    const [loadInfoDetail, setLoadInfoDetail] = useState(false);
    const bookingDataById = useSelector((state) => state.bookinglistdata.booking_active);
    const listNotify = useSelector((state) => state.bookingnotidata.notis);
    const [loadCallFromBooking, setLoadCallFromBooking] = useState(false);
    const [loadCallFromState, setLoadCallFromState] = useState(false);
    const settings = useSelector(state => state.settingsdata.settings);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [openSnackbarNoti, setOpenSnackbarNoti] = useState(true);
    const [bookingPending, setBookingPending] = useState(null);
    const [timeUpdate, setTimeUpdate] = useState(null);
    const [listCallDone, setListCallDone] = useState(null);
    const [notis, setNotis] = useState([]);

    useEffect(() => {
        dispatch(api.fetchCallsPending());
        dispatch(api.fetchNoteMapAddress());
        dispatch(api.fetchCallDetailAll());
        //Tự động load 10s
        if (intervalIdStore) {
        } else {
            const intervalId = setInterval(() => {
                let date1 = new Date();
                setTimeUpdate(date1.getTime())
                let minuteMin = settings.minuteBookingLater ? parseInt(settings.minuteBookingLater) : 32;
                getDriversCenter();
                api.getBookingLater().then(result => {
                    if (result) {
                        let bookings = Object.values(result);
                        if (bookings && bookings.length > 0) {
                            let bookingFilted = bookings.filter(booking => {
                                if (booking.bookingDate) {
                                    let date2 = new Date(booking.bookingDate);
                                    let bookingDateLaterMin = booking.bookingDateLaterMin ? parseInt(booking.bookingDateLaterMin) : '';
                                    if (bookingDateLaterMin) {
                                        let diffTime = date2.getTime() - date1.getTime();
                                        if ((diffTime > 0 && diffTime < bookingDateLaterMin && booking.bookLater) || diffTime < -1) {
                                            var pacallcenterdatas = store.getState().pacallcenterdata.calls;
                                            if (pacallcenterdatas) {
                                                let callsDone = pacallcenterdatas.filter(call => call.id == booking.callId);
                                                if (callsDone && callsDone.length > 0) {
                                                    if (callsDone[0].status != "pending") {
                                                        quickStart("Có cuốc khách đặt trước đón ở " + callsDone[0].note);
                                                        refApi.changeStateCallApi(callsDone[0].id, 'pending');
                                                        return true;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                return false;
                            });

                            if (bookingFilted && bookingFilted.length > 0) {
                                setOpenSnackbar(true);
                                setBookingPending(bookingFilted);
                                /*Promise.all(bookingFilted.map(async bk => {
                                    // Gửi tín hiệu

                                })).then(result => {
                                    if (result) {

                                    }
                                });*/
                            }

                            /*if (Array.isArray(listBk)) {
                                if (listBk && listBk.length > 0) {
                                    console.log("set ok 1");
                                    setBookingPending(listBk);
                                    setOpenSnackbar(true);
                                } else {
                                    setBookingPending(null);
                                }
                            } else {
                                if (typeof listBk == 'object') {
                                    listBk.then((result) => {
                                        if (result && result.length > 0) {
                                            console.log("set ok", result);
                                            setBookingPending(result);
                                            setOpenSnackbar(true);
                                        } else {
                                            setBookingPending(null);
                                        }
                                    }).catch((error) => {
                                        console.error(error);
                                    });
                                }

                            }*/

                        } else {
                            setBookingPending(null);
                        }
                    }
                });

            }, parseInt(settings.loadLocationSecond ? parseInt(settings.loadLocationSecond) : 10) * 1000);
            setIntervalIdStore(intervalId);
        }

        return () => {
            if (intervalIdStore) {
                clearInterval(intervalIdStore);
                setIntervalIdStore(null);
            }
            dispatch(api.stopFetchNoteMapAddress());
            dispatch(api.stopFetchCallDetailAll());
            dispatch(api.stopFetchCallsPending());
        };

    }, []);

    /*useEffect(() => {
        setBookingPending([]);
        let date1 = new Date();
        let minuteMin = settings.minuteBookingLater ? parseInt(settings.minuteBookingLater) : 32;
        if (bookingPending && bookingPending.length > 0) {
            let bookingActions = bookingPending.filter(booking => {
                let date2 = new Date(booking.bookingDate);
                let diffTime = date2.getTime() - date1.getTime();
                let diffMins = diffTime / (1000 * 60);
                if ((diffMins > 0 && diffMins < minuteMin && booking.bookLater) || diffMins < -1) {
                    return true;
                }
            }).map(bk => {
                // Gửi tín hiệu
                bk.requestedDrivers = null;
                bk.driverOutGroup = null;
                if (bk.carTypeCode && bk.pickup) {
                    let driverInOut = api.getDriversOutInGroup(settings.bankinh ? settings.bankinh : 3, bk.carTypeCode, bk.pickup);
                    if (driverInOut && driverInOut.driverOutGroup) {
                        bk.driverOutGroup = driverInOut.driverOutGroup;
                        return bk;
                    }
                }
            });
            console.log(bookingActions)
            if (bookingActions && bookingActions.length > 0) {
                setBookingPending(bookingActions);
                setOpenSnackbar(true);
            }
        }
    }, [timeStampReload]);*/

    useEffect(() => {
        if (bookingDataById && loadCallFromBooking) {
            if (bookingDataById.callId) {
                dispatch(api.fetchCallActive(bookingDataById.callId));
            }
            setLoadCallFromState(true);
            setLoadCallFromBooking(false);
        }

        if (callDetailData && loadCallFromState) {
            let callUpdate = {...callDetailData}
            if (bookingDataById && bookingDataById.status == "BOOKING_LATER") {
                callUpdate.isEditPickup = true;
            } else {
                callUpdate.isEditPickup = false;
            }

            dispatch({
                type: CALL_DETAIL_DATA, payload: callUpdate,
            });
            setLoadCallFromState(false);
        }

    }, [bookingDataById, callDetailData, loadCallFromBooking, loadCallFromState]);

    /*useEffect(() => {
        dispatch(fetchAllDrivers());
        return () => {
            dispatch(fetchAllDriversOff());
        };
    }, [dispatch, fetchAllDrivers, fetchAllDriversOff]);*/

    useEffect(() => {
        if (listNotify) {
            setNotis(listNotify);
        }

    }, [listNotify]);

    useEffect(() => {
        props.showDriver(rows);
    }, [rows]);

    useEffect(() => {
        if (callDetail && callDetail.id) {
            setActiveRow(callDetail.id);
        }
    }, [callDetail]);

    useEffect(() => {
        if (users) {
            let listUserLostConnect = [];
            var userFilter = users;
            if (filterDriver) {
                if (filterDriver.carTypeCode && filterDriver.carTypeCode != '') {
                    userFilter = userFilter.filter(u => u.carTypeCode == filterDriver.carTypeCode);
                }
                if (filterDriver.status && filterDriver.status != '') {
                    if (filterDriver.status === 'dangkinhdoanh') {
                        userFilter = userFilter.filter(u => u.driverActiveStatus == true);
                    }
                    if (filterDriver.status === 'dangcokhach') {
                        userFilter = userFilter.filter(u => u.queue == true);
                    }
                    if (filterDriver.status === 'dungkinhdoanh') {
                        userFilter = userFilter.filter(u => u.driverActiveStatus == false);
                    }
                    if (!isNaN(+filterDriver.status)) {
                        userFilter = userFilter.filter(u => {
                            let location = u.location;
                            if (location && location.lat) {
                                let groupFilter = group_kd.filter(g => g.value == filterDriver.status)[0];
                                if (groupFilter) {
                                    let areas = groupFilter.areas;
                                    if (areas) {
                                        for (let i = 0; i < areas.length; i++) {
                                            let resultPickup = geolib.isPointInPolygon({
                                                latitude: location.lat,
                                                longitude: location.lng
                                            }, areas[i]);
                                            if (resultPickup) {
                                                return true;
                                            }
                                        }
                                    } else {
                                        return true;
                                    }
                                }
                            }
                        });
                    }
                }

                if (filterDriver.search && filterDriver.search != '') {
                    userFilter = userFilter.filter(u => {
                        if (u.mobile.toLowerCase().includes(filterDriver.search.toLowerCase())
                            || u.vehicleNumber.toLowerCase().includes(filterDriver.search.toLowerCase())
                            || u.codeDriver.toLowerCase().includes(filterDriver.search.toLowerCase())
                            || u.firstName.toLowerCase().includes(filterDriver.search.toLowerCase())
                            || u.lastName.toLowerCase().includes(filterDriver.search.toLowerCase())
                        ) {
                            return true;
                        } else {
                            return false;
                        }
                    });
                }
            }

            userFilter = userFilter.sort(function (a, b) {
                if (!b.timeUpdate) {
                    b.timeUpdate = 0;
                }
                if (!a.timeUpdate) {
                    a.timeUpdate = 0;
                }
                return (b.timeUpdate - a.timeUpdate);
            });

            // Quýet lx mất kết nối local
            if (timeUpdate && userFilter) {
                userFilter = userFilter.map(u => {
                    if (u.location && u.location.at) {
                        let timeReload = settings.check2m ? parseInt(settings.check2m) : 2;
                        let timeReloadMiniSecond = timeReload * 60000;
                        if ((timeUpdate - u.location.at) > timeReloadMiniSecond) {
                            u.warningTimeUpdate = true;
                            listUserLostConnect.push(u.codeDriver);
                            if (!u.statusLostConnect) {
                                refApi.setUserLostConnectStatus(u.id, true);
                                RequestPushMsg(u.pushToken, language.notification_title, "Mất kết nối vị trí, vui lòng mở lại ứng dụng để cập nhật");
                            }
                        } else {
                            u.warningTimeUpdate = false;
                            if (u.statusLostConnect) {
                                refApi.setUserLostConnectStatus(u.id, false);
                            }
                        }
                    }
                    return u;
                });
            }

            dispatch({
                type: LIST_USER_LOST_CONNECT, payload: listUserLostConnect
            });

            setRows(userFilter);
        } else {
            getDriversCenter();
            setRows([]);
        }
    }, [users, filterDriver]);

    useEffect(() => {
        if (reportdriverdata.reports) {
            setDataRp(reportdriverdata.reports.filter(report => report.content !== ''));
            let listAlert = reportdriverdata.reports.filter(report => report.content.includes("hủy 1")
                || report.content.includes("hủy 0")
                || report.content.includes("hủy 2")
                || report.content.includes("hủy 3")
                || report.content.includes("hỗ trợ")
                || report.content.includes("vi phạm")
                || report.content.includes("nhận xe")
                || report.content.includes("đã đồng ý thay")
                || report.content.includes("hủy đặt xe")
                || report.content.includes("điểm đón cuốc khách")
                || report.content.includes("tự động hủy"));

            if (dataRpImportance) {
                if (listAlert && listAlert.length > dataRpImportance.length) {
                    if (listAlert[0]
                        && (listAlert[0].content.includes("hủy 0")
                            || listAlert[0].content.includes("hủy 0")
                            || listAlert[0].content.includes("hủy 2")
                            || listAlert[0].content.includes("hủy 3")
                            || listAlert[0].content.includes("tự động hủy")
                        )
                    ) {
                        //playSound2();
                    }

                    if (listAlert[0]
                        && (listAlert[0].content.includes("vi phạm")
                        )
                    ) {
                        //playSoundViPham();
                    }

                    if (listAlert[0]
                        && (listAlert[0].content.includes("hủy đặt xe")
                        )
                    ) {
                        //playSoundHuy();
                    }

                    if (listAlert[0]
                        && (listAlert[0].content.includes("đã đồng ý thay")
                        )
                    ) {
                        //playSoundDoiCa();
                    }

                    if (listAlert[0]
                        && (listAlert[0].content.includes("hỗ trợ")
                        )
                    ) {
                        //playSoundHoTro();
                    }
                }
            } else {
                //playSound2();
            }
            setDataRpImportance(listAlert);
        } else {
            setDataRp([]);
            setDataRpImportance([]);
        }
    }, [reportdriverdata.reports]);

    useEffect(() => {
        if (pacallcenterdatas.calls && pacallcenterdatas.calls.length > 0) {
            let resultCall = [];
            for (let i = 0; i < pacallcenterdatas.calls.length; i++) {
                if (
                    i > 0 &&
                    pacallcenterdatas.calls[i].source ===
                    pacallcenterdatas.calls[i - 1].source &&
                    ConvertTimeStampToDate(pacallcenterdatas.calls[i].time * 1000) ===
                    ConvertTimeStampToDate(
                        pacallcenterdatas.calls[i - 1].time * 1000
                    ) &&
                    pacallcenterdatas.calls[i].status ===
                    pacallcenterdatas.calls[i - 1].status
                ) {
                    continue;
                } else {
                    pacallcenterdatas.calls[i].date_formated = ConvertTimeStampToDate(
                        pacallcenterdatas.calls[i].time * 1000
                    );
                    pacallcenterdatas.calls[i].date_format_pa = ConvertTimeStampPaUnique(
                        pacallcenterdatas.calls[i].time * 1000
                    );
                    resultCall.push(pacallcenterdatas.calls[i]);
                }
            }

            let checkListCall1 = resultCall.filter(
                (item) =>
                    (!item.note || (item.note && item.note != "Khách quen lái xe")) &&
                    (!item.type || (item.type && item.type != "LX"))
            );

            if (filterCallType && filterCallType.carTypeCode && filterCallType.carTypeCode != '') {
                checkListCall1 = checkListCall1.filter((item) => (item.car && item.car.code === filterCallType.carTypeCode));
            }

            if (filterCallType && filterCallType.source && filterCallType.source != '') {
                checkListCall1 = checkListCall1.filter((item) => (item.source && item.source.includes(filterCallType.source)));
            }

            if (filterCallType && filterCallType.note && filterCallType.note != '') {
                checkListCall1 = checkListCall1.filter((item) => (item.note && item.note.includes(filterCallType.note)));
            }

            if (filterCallType && filterCallType.type && filterCallType.type != '') {
                if (filterCallType.type === 'chuaxuly') {
                    checkListCall1 = checkListCall1.filter((item) => {
                        if (!item.note) {
                            if (!item.reason) {
                                return true;
                            } else {
                                return false;
                            }
                        } else {
                            if (item.status == 'paid' || item.status == 'accept' || item.status == 'booking_later' || item.status == 'booked' || (item.reason && item.status == 'cancel')) {
                                return false;
                            } else {
                                return true;
                            }
                        }
                    });
                }
                /*if (filterCallType.type === 'huy') {
                    checkListCall1 = checkListCall1.filter((item) => item.status == 'cancel');
                }*/
                if (filterCallType.type === 'daxuly') {
                    checkListCall1 = checkListCall1.filter((item) => item.status && (item.note || item.reason) && (item.status == 'paid' || item.status == 'accept' || item.status == 'booked' || item.status == 'booking_later' || (item.reason && item.status == 'cancel')));
                    setListCallDone(checkListCall1);
                }
            }

            checkListCall1 = checkListCall1.sort((a, b) => (parseInt(a.time) < parseInt(b.time)) ? 1 : -1);

            if (filterCallType.type === 'chuaxuly' && checkListCall1.length > 0) {
                let phone = checkListCall1[checkListCall1.length - 1];
                setPhonePriority(phone);
                /*checkListCall1.unshift(checkListCall1[checkListCall1.length - 1]);
                checkListCall1.slice(0, -1);*/
            }

            setListCall(checkListCall1);
        } else {
            setListCall([]);
        }
    }, [pacallcenterdatas, filterCallType]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    async function quickStart(text) {
        Speech.speak(text, {
            language: 'vi-VN',
            name: 'vi-vn-x-gft-network',
            rate: 1,
            identifier: "vi-vn-x-gft-network",
            quality: "Enhanced",
        });
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleSelectChange = (event) => {
        setFilterDriver({...filterDriver, [event.target.name]: event.target.value})
    };

    const handleChangePage2 = (event, newPage) => {
        setPage2(newPage);
    };

    const handleChangeRowsPerPage2 = (event) => {
        setRowsPerPage2(+event.target.value);
        setPage2(0);
    };

    const columns = [{id: 'carTypeCode', label: 'carTypeCode', minWidth: 170}, {
        id: 'codeDriver', label: 'codeDriver', minWidth: 100
    },
        {
            id: 'timeUpdate',
            label: 'timeUpdate',
            minWidth: 170,
            align: 'right',
            format: (value) => ConverTimeStampToHHMM(value),
        }
    ];

    const callbackSnackbar = useCallback((row) => {
        setLoadInfoDetail(true);
        setLoadCallFromBooking(true);
        const callCenter = store.getState().pacallcenterdata.callCenter;
        const bookingActive = store.getState().bookinglistdata.booking_active;
        dispatch(api.resetTripData(callCenter && callCenter.id ? callCenter.id : null, bookingActive && bookingActive.id ? bookingActive.id : null));
        setTimeout(async () => {
            dispatch(api.resetTripData(row.callId, row.id));
            row.requestedDrivers = null;
            row.driverOutGroup = null;
            row.prepareBookingNow = true;
            dispatch({
                type: FETCH_BOOKING_BY_ID_SUCCESS,
                payload: {
                    booking_active: row
                },
            });
            if (row.callId) {
                dispatch(api.fetchCallActive(row.callId));
            }

            setLoadInfoDetail(false);
        }, [800]);
    }, []);

    const closeCalbackSnackbar = useCallback((event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    }, []);

    const callbackSnackbarNoti = useCallback((row) => {
        setLoadInfoDetail(true);
        setLoadCallFromBooking(true);
        setTimeout(async () => {
            if (row.params && row.params.callId && row.booking_id) {
                dispatch(api.fetchCallActive(row.params.callId));
                dispatch(api.fetchBookingById(row.booking_id, false));
            }
            setLoadInfoDetail(false);
        }, [800]);
    }, []);

    const closeCalbackSnackbarNoti = useCallback((event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbarNoti(false);
    }, []);


    return (<Box sx={[props.styleButton, {boxShadow: 3}]}>
        <Collapse orientation="horizontal" in={props.openRight} collapsedSize={1}>
            <IconButton
                sx={{boxShadow: 1}}
                aria-label="closeandopen"
                style={{
                    position: 'absolute',
                    marginLeft: -35,
                    borderRadius: 20,
                    borderWidth: 0.5,
                    backgroundColor: colors.WHITE,
                    marginTop: 50
                }}
                onClick={props.toggleRightDashboardComponent}
            >
                {props.openRight ? <ArrowRightIcon fontSize={"large"}/> : <ArrowLeftIcon fontSize={"large"}/>}
            </IconButton>

            <View style={{justifyContent: 'center', alignItems: 'center', width: '100%'}}>
                <Paper
                    component="form"
                    sx={{
                        display: 'flex',
                        alignItems: 'center', width: '80%',
                        marginTop: '10px', backgroundColor: '#d7d7d7',
                        borderRadius: 5
                    }}
                >
                    <IconButton sx={{p: '5px'}} aria-label="menu">
                        <SearchIcon/>
                    </IconButton>
                    <InputBase
                        size={'small'}
                        placeholder="Tìm kiếm"
                        value={value === 0 ? (filterDriver && filterDriver.search ? filterDriver.search : '') : (filterCallType && filterCallType.source ? filterCallType.source : '')}
                        name={'search'}
                        onChange={(event) => {
                            if (value === 0) {
                                handleSelectChange(event);
                            } else {
                                setFilterCallType({...filterCallType, source: event.target.value})
                            }
                        }
                        }
                    />
                </Paper>
            </View>

            {/*<Tabs value={value} onChange={handleChange} aria-label="icon tabs example">
                <Tab icon={<LocalTaxiIcon fontSize={"large"}/>} aria-label="taxi"/>
                <Tab icon={<PersonIcon fontSize={"large"}/>} aria-label="account"/>
            </Tabs>*/}

            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                //onChangeIndex={handleChangeIndex}
            >
                <TabPanel value={value} index={0} dir={theme.direction}>
                    <Box sx={{minWidth: 200}}>
                        <Grid container spacing={2} style={{alignItems: 'center'}}>
                            <Grid item xs={4}>
                                <Typography>Loại</Typography>
                            </Grid>
                            <Grid item xs={8} style={{marginTop: 10}}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label-loai" size={'small'}>Loại</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label-loai"
                                        id="demo-simple-select-loai"
                                        value={filterDriver && filterDriver.carTypeCode ? filterDriver.carTypeCode : ''}
                                        label="Loại"
                                        size={'small'}
                                        name={'carTypeCode'}
                                        onChange={(event) => {
                                            handleSelectChange(event);
                                        }
                                        }
                                    >
                                        <MenuItem value={""}>Tất cả</MenuItem>
                                        {
                                            cars.filter(c1 => c1.type == 'taxi').map(car => {
                                                return (
                                                    <MenuItem value={car.code}>
                                                        <ListItemText>{car.name}</ListItemText>
                                                    </MenuItem>
                                                )
                                            })
                                        }
                                        {/* <MenuItem value={"taxi"}>4 Ghế (Cơ bản)</MenuItem>
                                        <MenuItem value={'sedan'}>4 Ghể (Rộng)</MenuItem>
                                        <MenuItem value={'suv'}>7 Ghế</MenuItem>*/}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} style={{alignItems: 'center', marginTop: 10}}>
                            <Grid item xs={4}>
                                <Typography>Trạng thái</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label-status" size={'small'}>Trạng
                                        thái</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label-status"
                                        id="demo-simple-select-status"
                                        value={filterDriver && filterDriver.status ? filterDriver.status : ''}
                                        label="Trạng thái"
                                        name={"status"}
                                        size={'small'}
                                        onChange={(event) => {
                                            handleSelectChange(event);
                                        }
                                        }
                                    >
                                        <MenuItem value={""}>Tất cả</MenuItem>
                                        <MenuItem value={"dangkinhdoanh"}>Đang kinh doanh</MenuItem>
                                        <MenuItem value={"dangcokhach"}>Đang có khách</MenuItem>
                                        <MenuItem value={"dungkinhdoanh"}>Dừng kinh doanh</MenuItem>
                                        {group_kd.map(g => {
                                            return (
                                                <MenuItem value={g.value}>{g.label}</MenuItem>
                                            )
                                        })
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Button variant="contained"
                                onClick={() => {
                                    getDriversCenter();
                                }
                                }
                                style={{width: '100%'}} endIcon={<Typography fontSize={13}>{timeUpdate ? ConverTimeStampToHHMM(timeUpdate) : ""}</Typography>}>
                            Cập nhật mới
                        </Button>
                    </Box>
                    <Box sx={{minWidth: 200, marginTop: "10px"}}>
                        {rows ?
                            <Paper sx={{width: '100%', overflow: 'hidden'}}>
                                <TableContainer sx={{maxHeight: 300}}>
                                    <Table stickyHeader aria-label="sticky table" size={'small'}>
                                        <TableBody>
                                            {userdata_loading ?
                                                <TableRow hover role="checkbox" tabIndex={-1} key={"loadingrow_driver"}>
                                                    <TableCell key={"loadingcell_driver"} align={'center'} colSpan={6}>
                                                        <Box sx={{width: '100%'}}><LinearProgress/></Box>
                                                    </TableCell>
                                                </TableRow> : null}
                                            {rows
                                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((row) => {
                                                    return (<TableRow
                                                        hover role="checkbox" tabIndex={-1}
                                                        onClick={() => {
                                                            dispatch({
                                                                type: FETCH_BOOKING_TRACKER_SUCCESS,
                                                                payload: null
                                                            });
                                                            dispatch({
                                                                type: FETCH_BOOKING_BY_ID_SUCCESS,
                                                                payload: {
                                                                    booking_active: null
                                                                }
                                                            });
                                                            const callCenter = store.getState().pacallcenterdata.callCenter;
                                                            const bookingActive = store.getState().bookinglistdata.booking_active;
                                                            dispatch(api.resetTripData(callCenter && callCenter.id ? callCenter.id : null, bookingActive && bookingActive.id ? bookingActive.id : null));

                                                            if (codeDriverSelect && row["codeDriver"].toString() === codeDriverSelect) {
                                                                setCodeDriverSelect(null);
                                                                props.selectDriver(null);
                                                            } else {
                                                                setCodeDriverSelect(row["codeDriver"].toString());
                                                                props.selectDriver(row);
                                                                dispatch({
                                                                    type: UPDATE_ZOOM_MAP, payload: 16,
                                                                });
                                                            }

                                                            if (row.id) {
                                                                setLoadCallFromBooking(true);
                                                                dispatch(api.getBookingDriverTracked(row.id, false));
                                                            }

                                                            /*const callCenter = store.getState().pacallcenterdata.callCenter;
                                                            const bookingActive = store.getState().bookinglistdata.booking_active;
                                                            dispatch(api.resetTripData(callCenter && callCenter.id ? callCenter.id : null, bookingActive && bookingActive.id ? bookingActive.id : null));
                                                            setTimeout(() => {
                                                                dispatch(api.resetTripData(row.id, row.booking_id ? row.booking_id : null));
                                                                if (row.booking_id) {
                                                                    dispatch(api.fetchBookingById(row.booking_id, false));
                                                                }
                                                                let rowUpdate = row;
                                                                rowUpdate.customerName = customerName;
                                                                if (filterCallType.type != "daxuly") {
                                                                    rowUpdate.isEditPickup = true;
                                                                } else {
                                                                    rowUpdate.isEditPickup = false;
                                                                }
                                                                dispatch({
                                                                    type: CALL_DETAIL_DATA, payload: rowUpdate,
                                                                });
                                                                dispatch(api.fetchCallActive(row.id));
                                                                setLoadInfoDetail(false);
                                                            }, [800]);*/

                                                        }
                                                        }
                                                        style={{backgroundColor: row["codeDriver"].toString() === codeDriverSelect ? colors.GREY.secondary : colors.WHITE}}
                                                        key={row["codeDriver"].toString()}
                                                        selected={row["codeDriver"].toString() === codeDriverSelect}
                                                    >
                                                        {columns.map((column) => {
                                                            const value = row[column.id];
                                                            if (column.id === 'carTypeCode') {
                                                                return (<TableCell align={column.align}>
                                                                    <View style={{justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'row'}}>
                                                                        <TouchableOpacity
                                                                            onPress={() => {
                                                                                dispatch(api.updateUserLockStatus(row["id"], !row["lock_driver_status"]));
                                                                                alert("Vui lòng đợi cập nhật trạng thái khoá tài xế");
                                                                                getDriversCenter();
                                                                            }
                                                                            }
                                                                        >
                                                                            <img
                                                                                loading="lazy"
                                                                                width="30"
                                                                                style={{marginRight: 2}}
                                                                                src={cars.filter(c => c.code == row["carTypeCode"])[0].image}
                                                                            />
                                                                            <Text>{row["lock_driver_status"]}</Text>
                                                                        </TouchableOpacity>

                                                                        {row.lock_driver_status == true ?
                                                                            <LockIcon fontSize={'small'} color={"error"}/> : row.warningTimeUpdate ?
                                                                                <WarningAmberIcon fontSize={'small'} color={"error"}/> : null
                                                                        }

                                                                    </View>

                                                                </TableCell>)
                                                            } else if (column.id === 'codeDriver') {
                                                                var color = colors.TRANSPARENT;
                                                                if (row["driverActiveStatus"] == false) {
                                                                    color = colors.RED;
                                                                }
                                                                if (row["queue"] == true) {
                                                                    color = colors.BLUE.secondary;
                                                                }
                                                                return (<TableCell align={column.align}>
                                                                    <View style={{flexDirection: 'column'}}>
                                                                        <Text style={{
                                                                            fontWeight: 'bold',
                                                                            backgroundColor: color,
                                                                            color: row["driverActiveStatus"] ? (row["queue"] ? colors.WHITE : colors.GREY.iconSecondary) : colors.WHITE,
                                                                        }}>{value.trim()}</Text>
                                                                        <Text style={{
                                                                            color: colors.GREY.iconSecondary,
                                                                            fontSize: 10
                                                                        }}>{row["mobile"].replaceAll("+840", "0").replaceAll("+84", '')}</Text>
                                                                    </View>
                                                                </TableCell>)
                                                            } else if (column.id === 'vehicleNumber') {
                                                                return (<TableCell align={column.align}>
                                                                    <View style={{flexDirection: 'column'}}>
                                                                        <Text
                                                                            style={{fontWeight: 'bold'}}>{value}</Text>
                                                                        <Text style={{
                                                                            color: colors.GREY.iconSecondary,
                                                                            fontSize: 10
                                                                        }}>{row["firstName"] + " " + row["lastName"]}</Text>
                                                                    </View>
                                                                </TableCell>)
                                                            } else if (column.id === 'timeUpdate') {
                                                                return (<TableCell align={column.align}>
                                                                    <View style={{flexDirection: 'column'}}>
                                                                        <Text
                                                                            style={{fontWeight: 'bold'}}>{column.format(value)}</Text>
                                                                        <Text style={{
                                                                            color: colors.GREY.iconSecondary,
                                                                            fontSize: 10
                                                                        }}>{row["timestampingroup"] != '9999999999999' ? ConverTimeStampToHHMM(row["timestampingroup"]) : ''}</Text>
                                                                    </View>
                                                                </TableCell>)
                                                            } else {
                                                                return (<TableCell align={column.align}>
                                                                    {column.format && typeof value === 'number' ? column.format(value) : value}
                                                                </TableCell>);
                                                            }

                                                        })}
                                                    </TableRow>);
                                                })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 100]}
                                    component="div"
                                    count={rows.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </Paper> : null}
                    </Box>
                </TabPanel>
                <TabPanel value={value} index={1} dir={theme.direction}>
                    <Box sx={{minWidth: 200}}>
                        <Paper sx={{overflow: 'hidden'}}>
                            <TableContainer sx={{maxHeight: height - 200}} key={"key_table_call"}>
                                <Table stickyHeader aria-label="sticky table" size={'small'}>
                                    <TableHead>
                                        <TableRow key={"row_header_callcenter"}>
                                            <TableCell
                                                key={"time_header"}
                                                align={'left'}
                                                colSpan={filterCallType && filterCallType.type == 'daxuly' ? 4 : 3}
                                            >
                                                <View style={{
                                                    backgroundColor: '#cbcbcb', borderRadius: 10, width: '100%'
                                                }}>
                                                    <View style={{
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                        justifyContent: 'space-between',
                                                    }}>

                                                        <TouchableOpacity
                                                            onPress={() => {
                                                                setFilterCallType({...filterCallType, type: 'chuaxuly'});
                                                                setPage(0);
                                                                setPage2(0)
                                                            }
                                                            }
                                                            style={{padding: 8, height: '100%', paddingLeft: 10, width: '49%', paddingRight: 10, borderRadius: 5, backgroundColor: filterCallType.type === 'chuaxuly' ? colors.BACKGROUND_DARK : "#cbcbcb", justifyContent: 'center', alignItems: 'center'}}
                                                        >
                                                            <Text style={{color: filterCallType.type === 'chuaxuly' ? colors.WHITE : colors.BACKGROUND_DARK, fontSize: 11}}>{"Chưa xử lý"}</Text>
                                                        </TouchableOpacity>
                                                        <TouchableOpacity
                                                            onPress={() => {
                                                                setFilterCallType({...filterCallType, type: 'daxuly'});
                                                                setPage(0);
                                                                setPage2(0)
                                                            }
                                                            }
                                                            style={{padding: 8, paddingLeft: 10, height: '100%', paddingRight: 10, width: '49%', borderRadius: 5, backgroundColor: filterCallType.type === 'daxuly' ? colors.BACKGROUND_DARK : "#cbcbcb", justifyContent: 'center', alignItems: 'center'}}
                                                        >
                                                            <Text style={{color: filterCallType.type === 'daxuly' ? colors.WHITE : colors.BACKGROUND_DARK, fontSize: 11}}>{"Đã xử lý"}</Text>
                                                        </TouchableOpacity>
                                                    </View>
                                                </View>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {pacallcenterdatas.loading ?
                                            <TableRow hover role="checkbox" tabIndex={-1} key={"loadingrow"}>
                                                <TableCell key={"loadingcell"} align={'center'} colSpan={3}>
                                                    <Box sx={{width: '100%'}}><LinearProgress/></Box>
                                                </TableCell>
                                            </TableRow> : null}
                                        {listCall
                                            .slice(page2 * rowsPerPage2, page2 * rowsPerPage2 + rowsPerPage2)
                                            .map((row) => {
                                                let phone = row['source'];
                                                let customerName = callDetailData && callDetailData[phone] && callDetailData[phone].alias ? callDetailData[phone].alias : 'Chưa có';
                                                var statusPendingCall = false;
                                                if (!row.note) {
                                                    if (!row.reason) {
                                                        statusPendingCall = true;
                                                    } else {
                                                        statusPendingCall = false;
                                                    }
                                                } else {
                                                    if (row.status == 'paid' || row.status == 'accept' || row.status == 'booked' || (row.reason && row.status == 'cancel')) {
                                                        statusPendingCall = false;
                                                    } else {
                                                        statusPendingCall = true;
                                                    }
                                                }

                                                let statusCancelCall = row.status == 'cancel';
                                                //let statusDoneCall = row.status && row.note && (row.status == 'paid' || row.status == 'accept' || row.status == 'booked_cancel' || (row.reason && row.status == 'cancel'));

                                                return (
                                                    <TableRow hover role="checkbox"
                                                              tabIndex={-1}
                                                              key={row.id}
                                                              sx={{
                                                                  backgroundColor: activeRow === row.id ? '#d2d2d2' : 'white', // Custom blue highlight
                                                              }}>

                                                        <TableCell align={'left'}>
                                                            <View>
                                                                <TouchableOpacity
                                                                    onPress={() => {
                                                                        props.openCallPopupCallback(row["source"]);
                                                                    }
                                                                    }
                                                                >
                                                                    <Text style={{fontWeight: 'bold', color: row.status === 'cancel' || row.status === 'booked_cancel' ? colors.RED : colors.BACKGROUND_DARK}}>{(auth && auth.usertype == 'admin') || (row['total_cancel'] && row['total_cancel'] > 1) ? row["source"] : HiddenPhone(row["source"])}</Text>
                                                                </TouchableOpacity>
                                                                <Text style={{fontSize: 11,}}>{ConvertTimeStampTimeForPaShort(row["time"] * 1000)}</Text>
                                                            </View>
                                                        </TableCell>
                                                        <TableCell align={'left'}>
                                                            {filterCallType && filterCallType.type == 'chuaxuly' ?
                                                                <IconButton
                                                                    color="inherit"
                                                                    aria-label="open drawer"
                                                                    edge="start"
                                                                    onClick={() => {
                                                                        setActiveRow(row.id);
                                                                        props.openCancelCallback(row);
                                                                    }
                                                                    }
                                                                >
                                                                    {row.status === 'booked' ?
                                                                        <Box sx={{display: 'flex'}}>
                                                                            <CircularProgress size={20}/>
                                                                        </Box> :
                                                                        <RemoveCircleOutlineRoundedIcon color={"action"} fontSize={"medium"} style={{backgroundColor: colors.TRANSPARENT, borderRadius: 15}}/>
                                                                    }
                                                                </IconButton> :
                                                                <View>
                                                                    {row.driver_code ?
                                                                        <IconButton
                                                                            color="inherit"
                                                                            aria-label="open drawer"
                                                                            edge="start"
                                                                            onClick={() => {
                                                                                setActiveRow(row.id);
                                                                                dispatch({
                                                                                    type: UPDATE_ZOOM_MAP, payload: 16,
                                                                                });
                                                                                if (row.accept_driver && users) {
                                                                                    let drivers = users.filter(u => u.id === row.accept_driver);
                                                                                    if (drivers) {
                                                                                        props.selectDriver(drivers[0]);
                                                                                    }
                                                                                }
                                                                                //props.openCancelCallback(row);
                                                                            }
                                                                            }
                                                                        >
                                                                            {row.status === 'booked' ?
                                                                                <Box sx={{display: 'flex'}}>
                                                                                    <CircularProgress size={20}/>
                                                                                </Box> :
                                                                                row.driver_code ?
                                                                                    <Text>{row.driver_code}</Text> : null
                                                                            }
                                                                        </IconButton> :
                                                                        row.status === 'booking_later' ?
                                                                            <IconButton
                                                                                color="inherit"
                                                                                aria-label="open drawer"
                                                                                edge="start"
                                                                                onClick={() => {
                                                                                    setActiveRow(row.id);
                                                                                    dispatch({
                                                                                        type: UPDATE_ZOOM_MAP, payload: 16,
                                                                                    });
                                                                                    if (row.accept_driver && users) {
                                                                                        let drivers = users.filter(u => u.id === row.accept_driver);
                                                                                        if (drivers) {
                                                                                            props.selectDriver(drivers[0]);
                                                                                        }
                                                                                    }


                                                                                    //props.openCancelCallback(row);
                                                                                }
                                                                                }
                                                                            >
                                                                                {row.status === 'booked' ?
                                                                                    <Box sx={{display: 'flex'}}>
                                                                                        <CircularProgress size={20}/>
                                                                                    </Box> :
                                                                                    <View style={{flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                                                                                        <AccessTimeIcon color={"action"} fontSize={"medium"} style={{backgroundColor: colors.TRANSPARENT, borderRadius: 15}}/>
                                                                                        <Text style={{fontSize: 11}}>{row.booking_schedule ? ConvertTimeStampTimeForPaShort(row.booking_schedule) : ''}</Text>
                                                                                    </View>
                                                                                }
                                                                            </IconButton> : null
                                                                    }
                                                                </View>

                                                            }
                                                        </TableCell>
                                                        {filterCallType.type && filterCallType.type == "daxuly" ?
                                                            <TableCell align={'left'}>
                                                                <IconButton
                                                                    color="inherit"
                                                                    aria-label="open drawer"
                                                                    edge="start"
                                                                    onClick={() => {
                                                                        setActiveRow(row.id);
                                                                        if (row.booking_id) {
                                                                            dispatch(api.fetchBookingById(row.booking_id, false));
                                                                        } else {
                                                                            dispatch({
                                                                                type: FETCH_BOOKING_BY_ID_SUCCESS,
                                                                                payload: {
                                                                                    booking_active: null
                                                                                },
                                                                            });
                                                                        }
                                                                        props.openAddCallCallback(row);
                                                                    }
                                                                    }
                                                                >
                                                                    <AddCircleOutlineRoundedIcon color={"action"} fontSize={"medium"} style={{backgroundColor: colors.TRANSPARENT, borderRadius: 15}}/>
                                                                </IconButton>
                                                            </TableCell> : null}

                                                        <TableCell align={'left'}>
                                                            <IconButton
                                                                color="inherit"
                                                                aria-label="open drawer"
                                                                edge="start"
                                                                //disabled={!(phonePriority && phonePriority.time && row.time == phonePriority.time)}
                                                                onClick={() => {
                                                                    setActiveRow(row.id);
                                                                    setLoadInfoDetail(true);
                                                                    const callCenter = store.getState().pacallcenterdata.callCenter;
                                                                    const bookingActive = store.getState().bookinglistdata.booking_active;
                                                                    dispatch(api.resetTripData(callCenter && callCenter.id ? callCenter.id : null, bookingActive && bookingActive.id ? bookingActive.id : null));
                                                                    setTimeout(() => {
                                                                        dispatch(api.resetTripData(row.id, row.booking_id ? row.booking_id : null));
                                                                        if (row.booking_id) {
                                                                            dispatch(api.fetchBookingById(row.booking_id, false));
                                                                        }
                                                                        let rowUpdate = row;
                                                                        rowUpdate.customerName = customerName;
                                                                        if (filterCallType.type != "daxuly") {
                                                                            rowUpdate.isEditPickup = true;
                                                                        } else {
                                                                            rowUpdate.isEditPickup = false;
                                                                        }
                                                                        dispatch({
                                                                            type: CALL_DETAIL_DATA, payload: rowUpdate,
                                                                        });
                                                                        dispatch(api.fetchCallActive(row.id));
                                                                        setLoadInfoDetail(false);
                                                                    }, [800]);
                                                                }}
                                                            >
                                                                {loadInfoDetail && row.id === activeRow ?
                                                                    <Box sx={{display: 'flex'}}>
                                                                        <CircularProgress size={20}/>
                                                                    </Box> :
                                                                    <DirectionsIcon color={!(phonePriority && phonePriority.time && row.time == phonePriority.time) ? "action" : "primary"} fontSize={"medium"}/>}
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 100]}
                                component="div"
                                count={listCall.length}
                                rowsPerPage={rowsPerPage2}
                                page={page2}
                                onPageChange={handleChangePage2}
                                onRowsPerPageChange={handleChangeRowsPerPage2}
                            />
                        </Paper>
                    </Box>
                </TabPanel>
            </SwipeableViews>
        </Collapse>
        {
            bookingPending ? bookingPending.map(bk => {
                if (bk && bk.bookingDate) {
                    return <BookingLaterSnackbarComponent open={openSnackbar} callbackSnackbar={callbackSnackbar} closeCalbackSnackbar={closeCalbackSnackbar} booking={bk}/>
                } else {
                    return null;
                }

            }) : null
        }

        {
            listNotify ? listNotify.map(bk => {
                return <NotifyCenterSnackbarComponent open={openSnackbarNoti} callbackSnackbar={callbackSnackbarNoti} closeCalbackSnackbar={closeCalbackSnackbarNoti} noti={bk}/>
            }) : null
        }

    </Box>)
        ;
}
