import {
    CLEAR_BOOKING,
    CONFIRM_BOOKING,
    CONFIRM_BOOKING_FAILED,
    CONFIRM_BOOKING_SUCCESS,
    FETCH_BOOKING_DETAIL,
    FETCH_BOOKING_DETAIL_BY_CODE,
    FETCH_BOOKING_DETAIL_BY_CODE_FAILED,
    FETCH_BOOKING_DETAIL_BY_CODE_STOP,
    FETCH_BOOKING_DETAIL_BY_CODE_SUCCESS,
    FETCH_BOOKING_DETAIL_FAILED,
    FETCH_BOOKING_DETAIL_SUCCESS, FETCH_NEWS_BOOKINGS_SUCCESS,
    SELECT_BOOKING_DETAIL,
    SELECT_BOOKING_DETAIL_FAILED,
    SELECT_BOOKING_DETAIL_ON,
    SELECT_BOOKING_DETAIL_ON_FAILED,
    SELECT_BOOKING_DETAIL_ON_SUCCESS,
    SELECT_BOOKING_DETAIL_SUCCESS,
    SELECT_BOOKING_DRIVER,
    SELECT_BOOKING_DRIVER_FAILED,
    SELECT_BOOKING_DRIVER_SUCCESS,
    UPDATE_SESSION_BOOKING_ID
} from "../store/types";
import {ConvertTimeStampToDate, GetCurrentMiniTimeStamp, GetCurrentTimeStamp} from "../other/DateFunctions";
import {RequestPushMsg} from "../other/NotificationFunctions";
import {language} from "config";
import {formatDisplayDate, getUserValidSign} from "../other/CommonFunctions";
import {getDriveTime} from "../other/GoogleAPIFunctions";
import {GetDistance} from "../other/GeoFunctions";
import {checkDriverValid, prepareDataBeforAddBookingV2} from "../other/ActionHelper";
import {firebase} from '../config/configureFirebase';
import {off, onValue, set, child, update, remove, push, get, query, orderByChild, equalTo} from "firebase/database";
import store from "../store/store";
import {pushNotifyImportance} from "./notifyimportanceaction";
import {addActualsToBooking} from "../other/sharedFunctions";

export const clearBooking = () => (dispatch) => {
    dispatch({
        type: CLEAR_BOOKING, payload: null,
    });
}

export const fetchBookingOn = (bookingKey) => (dispatch) => {
    const {
        singleBookingRef
    } = firebase;

    dispatch({
        type: FETCH_BOOKING_DETAIL, booking: null
    });
    off(singleBookingRef(bookingKey));
    onValue(singleBookingRef(bookingKey), snapshot => {
        if (snapshot.val()) {
            const data = snapshot.val();
            dispatch({
                type: FETCH_BOOKING_DETAIL_SUCCESS, booking: data
            });
        } else {
            dispatch({
                type: FETCH_BOOKING_DETAIL_FAILED, booking: "No booking available."
            });
        }
    });
}

export const stopFetchBookingOn = (bookingKey) => (dispatch) => {
    const {
        singleBookingRef
    } = firebase;
    off(singleBookingRef(bookingKey));
    dispatch({
        type: FETCH_BOOKING_DETAIL_FAILED, booking: null
    });

}

export const bookingDetail = (bookingKey) => (dispatch) => {
    const {
        singleBookingRef
    } = firebase;

    dispatch({
        type: SELECT_BOOKING_DETAIL, bookingDetail: null
    });

    onValue(singleBookingRef(bookingKey), snapshot => {
        if (snapshot.val()) {
            let data = snapshot.val();
            data.id = bookingKey;
            dispatch({
                type: SELECT_BOOKING_DETAIL_SUCCESS, bookingDetail: data
            });
        } else {
            dispatch({
                type: SELECT_BOOKING_DETAIL_FAILED, bookingDetail: null
            });
        }
    }, {onlyOnce: true});
}


export const getBookingDetail = async (bookingKey) => {
    const {
        singleBookingRef
    } = firebase;
    if (bookingKey) {
        let booking = await get(singleBookingRef(bookingKey)).then(async (snapshot) => {
            if (snapshot.val()) {
                const data = snapshot.val();
                data.id = bookingKey;
                return data;
            } else {
                return null;
            }
        });
        return booking;
    } else {
        return null;
    }

}

export const bookingDetailOn = (bookingKey, bankinh = 3, notify = null) => (dispatch) => {
    const {
        singleBookingRef, paCallByIdRef, requestedDriversRef, countTimeRef, reportdriverRef, notifyImportanceToRef, singleUserRef, notifyImportanceIdRef
    } = firebase;

    dispatch({
        type: SELECT_BOOKING_DETAIL_ON, bookingOn: null
    });
    let settings = store.getState().settingsdata.settings;
    var resultOut = [];
    var resultInArea = [];
    let users = store.getState().usersdata.once_drivers;
    let cars = store.getState().cartypes.cars;
    const profile = store.getState().auth.user;
    const groups = store.getState().groupdriverdata.group_kd;
    var driver_favourite = null;
    if (profile && profile.driver_favourite) {
        driver_favourite = store.getState().auth.user.driver_favourite;
    }
    let localString = ConvertTimeStampToDate(GetCurrentMiniTimeStamp());
    onValue(singleBookingRef(bookingKey), snapshot => {
        if (snapshot.val()) {
            const data = snapshot.val();
            onValue(notifyImportanceToRef(data.customer), (snapshot) => {
                if (snapshot.val()) {
                    let dataNotify = snapshot.val();
                    const arr = Object.keys(dataNotify)
                        .filter(i => dataNotify[i].booking_id && dataNotify[i].booking_id == bookingKey)
                        .map(i => {
                            dataNotify[i].id = i;
                            return dataNotify[i];
                        });

                    if (arr && arr.length > 0) {
                        let notify = arr[0];
                        let dataNotify = {
                            title: "Đang tìm kiếm lái xe", content: "Vui lòng đợi trong giây lát để tài xế nhận cuốc",
                        };
                        update(notifyImportanceIdRef(notify.id), dataNotify);
                    }
                }
            }, {onlyOnce: true});
            let carType = data.carTypeCode;
            if (cars && cars.length > 0) {
                let carSelect = cars.filter((car) => {
                    if (car.code === carType) {
                        return true;
                    }
                });
                if (carSelect[0]) {
                    let carSelected = carSelect[0];

                    var listDrivers = [];
                    if (carSelected.driverCodes) {
                        listDrivers = carSelected.driverCodes.split(",");
                    }
                    if (users && users.length > 0) {
                        var pickupValid = null;
                        if (data.pickup && data.pickup.lat) {
                            pickupValid = {lat: data.pickup.lat, lng: data.pickup.lng};
                        } else {
                            if (data.driver_group && data.driver_group.coord) {
                                pickupValid = {lat: data.driver_group.coord.split(",")[0].toString().trim(), lng: data.driver_group.coord.split(",")[1].toString().trim()};
                            }
                        }
                        const promises = [];

                        for (let i = 0; i < users.length; i++) {
                            let usr = users[i];
                            if (data.customer && data.type == "LX" && usr.id == data.customer) {
                                continue;
                            }
                            if (driver_favourite !== null) {
                                if (driver_favourite[usr.id] && driver_favourite[usr.id].status === false) {
                                    continue;
                                }
                            }

                            var groupValid = null;
                            let group = data.driver_group ? data.driver_group.value : null;
                            if (group && groups) {
                                groupValid = groups.filter((g) => {
                                    if (g.value && g.value == group) {
                                        return true;
                                    }
                                })[0];
                            }
                            promises.push(getUserValidSign(usr, bankinh, pickupValid, listDrivers, data.carTypeCode, resultOut, null, groupValid, data.areaId ? data.areaId : null, resultInArea));
                        }

                        Promise.all(promises)
                            .then((results) => {
                                let listArea = resultOut
                                if (listArea && listArea.length > 0) {
                                    let pushed_drivers_value_array = [];
                                    let bookingPushOutList = [];
                                    listArea.map((driverR) => {
                                        pushed_drivers_value_array.push(driverR.codeDriver);
                                        if (data.driverBook && data.driverBook.code) {
                                            if (driverR.codeDriver !== data.driverBook.code.toString().trim()) {
                                                Object.assign(bookingPushOutList, {[driverR.id]: true});
                                            }
                                        } else {
                                            Object.assign(bookingPushOutList, {[driverR.id]: true});
                                        }
                                    });

                                    let pushed_drivers_value = pushed_drivers_value_array && pushed_drivers_value_array.length > 0 ? pushed_drivers_value_array.join(',') : null;
                                    update(singleBookingRef(bookingKey), {
                                        status: "NEWV2", driverInArea: listArea, count_time: 'BAO_TIN_HIEU_LAN_2_REQUEST_DONE', isDriverNotMistake: false, pushed_drivers: pushed_drivers_value, driver: '', driver_accepted: '', vipStatus: false, codeDriver: ''
                                    }).then((r) => {
                                        set(countTimeRef(bookingKey), "BAO_TIN_HIEU_LAN_1");
                                        if (bookingPushOutList) {
                                            set(requestedDriversRef(bookingKey), bookingPushOutList);
                                            var totalTimes = 1;
                                            let countDownBooking = setInterval(() => {
                                                if (totalTimes >= 10) {
                                                    //countTimeStampRef(bookingKey).set(GetCurrentTimeStamp());
                                                    clearInterval(countDownBooking);
                                                } else {
                                                    set(countTimeRef(bookingKey), totalTimes);
                                                    totalTimes = totalTimes + 1;
                                                }
                                            }, 1000);

                                            setTimeout(() => {
                                                get(singleBookingRef(bookingKey)).then(async (snapshot2) => {
                                                    if (snapshot2.val()) {
                                                        const bookingNew = snapshot2.val();
                                                        bookingNew.id = bookingKey;
                                                        var taskUpdate = {};
                                                        var driver_accepted_array = bookingNew.driver_accepted ? Object.values(bookingNew.driver_accepted) : [];
                                                        var sorted = driver_accepted_array ? checkDriverValid(bookingNew.customer_contact, driver_accepted_array, settings, bookingNew.customer_signup_via_referral, bookingNew.customer_contact_limited, false) : [];
                                                        if (sorted[0] && sorted[0].uid) {
                                                            let driverId = sorted[0].uid;
                                                            onValue(singleUserRef(driverId), res => {
                                                                let driverObj = res.val();
                                                                if (driverObj) {
                                                                    /*var newData = {};
                                                                    newData.content = localString + " [" + booking.customer_note + "] Tài xế #" + profile.codeDriver + " đã nhận #" + bookingKey;
                                                                    reportdriverRef.push(newData);*/
                                                                    taskUpdate.driver = driverId;
                                                                    taskUpdate.driver_image = driverObj.avatar ? driverObj.avatar : "";
                                                                    taskUpdate.driver_name = driverObj.firstName + ' ' + driverObj.lastName;
                                                                    taskUpdate.driver_contact = driverObj.mobile;
                                                                    taskUpdate.driver_token = driverObj.pushToken;
                                                                    taskUpdate.driver_bankCode = driverObj.bankCode;
                                                                    taskUpdate.driver_bankAccount = driverObj.bankAccount;
                                                                    taskUpdate.vehicle_number = driverObj.vehicleNumber;
                                                                    taskUpdate.codeDriver = driverObj.codeDriver;
                                                                    taskUpdate.driverRating = driverObj.ratings ? driverObj.ratings.userrating : "0";
                                                                    taskUpdate.fleetadmin = driverObj.fleetadmin ? driverObj.fleetadmin : '';
                                                                    taskUpdate.accept_time = GetCurrentMiniTimeStamp();
                                                                    taskUpdate.notify_to_center = localString + " Tài xế #" + driverObj.codeDriver + " Đã nhận #" + bookingKey;
                                                                    taskUpdate.status = "ACCEPTED";
                                                                    taskUpdate.count_time = "LAI_XE_DA_NHAN";
                                                                    taskUpdate.driverType = driverObj.driverType;
                                                                    //taskUpdate.driver_accepted = null;
                                                                    taskUpdate.driverCurrentAddress = driverObj.location;
                                                                    taskUpdate.driver_location = {
                                                                        at: GetCurrentMiniTimeStamp(), lat: driverObj.location.lat, lng: driverObj.location.lng,
                                                                    };
                                                                    taskUpdate.accept_time_stone = driverObj.location;
                                                                    taskUpdate.isDriverNotMistake = false;

                                                                    if (settings && settings.approve_by_vip && driverObj.driverVipStatus) {
                                                                        taskUpdate.vipStatus = true;
                                                                    }
                                                                    taskUpdate.perUseDriver = driverObj.perUse ? driverObj.perUse : 0;
                                                                    taskUpdate.perDriver = driverObj.per ? driverObj.per : 0;
                                                                    taskUpdate.prices = bookingNew.prices ? bookingNew.prices : (sorted[0].prices ? sorted[0].prices : null);
                                                                    taskUpdate.customer_wish = false;
                                                                    update(singleBookingRef(bookingKey), taskUpdate);
                                                                    if (bookingNew.type == 'LX' && bookingNew.customer) {
                                                                        var returnedTarget = Object.assign(bookingNew, taskUpdate);
                                                                        returnedTarget.status = taskUpdate.status;
                                                                        onValue(notifyImportanceToRef(bookingNew.customer), (snapshot) => {
                                                                            if (snapshot.val()) {
                                                                                let dataNotify = snapshot.val();
                                                                                const arr = Object.keys(dataNotify)
                                                                                    .filter(i => dataNotify[i].type == "booking_request" && dataNotify[i].booking_id && dataNotify[i].booking_id == bookingKey)
                                                                                    .map(i => {
                                                                                        dataNotify[i].id = i;
                                                                                        return dataNotify[i];
                                                                                    });

                                                                                if (arr && arr.length > 0) {
                                                                                    let startLoc = driverObj.location.lat + ',' + driverObj.location.lng;
                                                                                    let destLoc = bookingNew.pickup.lat + ',' + bookingNew.pickup.lng;
                                                                                    /*getDriveTime(startLoc, destLoc).then((arriveTime2) => {
                                                                                        if (arriveTime2 && arriveTime2.time_in_secs) {
                                                                                            let notify = arr[0];
                                                                                            let dataNotify = {
                                                                                                title: "Cuốc khách đang được phục vụ", content: "Tài xế sẽ đến điểm đón lúc " + formatDisplayDate(ConvertTimeStampToDate((parseInt(arriveTime2.time_in_secs) * 1000) + GetCurrentMiniTimeStamp())), params: [returnedTarget]
                                                                                            };
                                                                                            update(notifyImportanceIdRef(notify.id), dataNotify);
                                                                                        } else {
                                                                                            let notify = arr[0];
                                                                                            let dataNotify = {
                                                                                                title: "Cuốc khách đang được phục vụ", content: "Tài xế sẽ đến điểm đón trong ít phút ", params: [returnedTarget]
                                                                                            };
                                                                                            update(notifyImportanceIdRef(notify.id), dataNotify);
                                                                                        }
                                                                                    });*/
                                                                                    let notify = arr[0];
                                                                                    let dataNotify = {
                                                                                        title: "Cuốc khách đang được phục vụ", content: "Tài xế sẽ đến điểm đón trong ít phút ", params: [returnedTarget]
                                                                                    };
                                                                                    update(notifyImportanceIdRef(notify.id), dataNotify);
                                                                                }
                                                                            }
                                                                        }, {onlyOnce: true});
                                                                    }
                                                                }
                                                            }, {onlyOnce: true});
                                                        } else {
                                                            console.log("sorted 2");
                                                            await set(countTimeRef(bookingKey), "BAO_TIN_HIEU_LAN_2_REQUEST");
                                                            console.log("sorted 3");
                                                            await update(singleBookingRef(bookingKey), {
                                                                driver_mark_ignore_in: bookingNew.bookingPushInList ? bookingNew.bookingPushInList : null, reason: 'Không có chuyến gần đây', cancel_time: GetCurrentMiniTimeStamp(), notify_to_center: "Không có chuyến gần đây", status: "CANCELLED",
                                                                total_cancel: 1
                                                            });
                                                            console.log("sorted 4");
                                                            if (bookingNew.callId) {
                                                                await update(paCallByIdRef(bookingNew.callId), {status: "BAO_TIN_HIEU_LAN_2_REQUEST", total_cancel: 1});
                                                            }
                                                            console.log("sorted 5");
                                                            if (bookingNew.type == 'LX' && bookingNew.customer) {
                                                                onValue(notifyImportanceToRef(data.customer), (snapshot) => {
                                                                    if (snapshot.val()) {
                                                                        let dataNotify = snapshot.val();
                                                                        const arr = Object.keys(dataNotify)
                                                                            .filter(i => dataNotify[i].type == "booking_request" && dataNotify[i].booking_id && dataNotify[i].booking_id == bookingKey)
                                                                            .map(i => {
                                                                                dataNotify[i].id = i;
                                                                                return dataNotify[i];
                                                                            });

                                                                        if (arr && arr.length > 0) {
                                                                            let notify = arr[0];
                                                                            let dataNotifyUpdate = {
                                                                                title: "Cuốc khách không tìm thấy lái xe", content: "Có vẻ các tài xế đang bận, vui lòng đặt lại sau ít phút",
                                                                            };
                                                                            update(notifyImportanceIdRef(notify.id), dataNotifyUpdate);
                                                                        }
                                                                    }
                                                                }, {onlyOnce: true});
                                                            }
                                                        }
                                                    }
                                                    console.log("sorted 7");
                                                    await remove(requestedDriversRef(bookingKey));
                                                    dispatch({
                                                        type: SELECT_BOOKING_DETAIL_ON_SUCCESS, bookingOn: data
                                                    });
                                                }).catch(error => {
                                                    dispatch({
                                                        type: SELECT_BOOKING_DETAIL_ON_SUCCESS, bookingOn: data
                                                    });
                                                });
                                            }, 23000);

                                        }
                                    }).catch((error) => {

                                        if ((data.reason_type && data.reason_type == 'rider') || data.status == "CANCELLED") {
                                        } else {
                                            if (data.customer_token) {
                                                RequestPushMsg(data.customer_token, language.notification_title, "Yêu cầu đặt xe tự động hủy do các tài xế đang bận!");
                                            }
                                            let msg = ConvertTimeStampToDate(GetCurrentMiniTimeStamp()) + ' Cuốc xe đã tự động hủy 0 do không có lái xe phù hợp #' + bookingKey;
                                            push(reportdriverRef, {content: msg});
                                            set(countTimeRef(bookingKey), "KHONG_BAO_TIN_HIEU");
                                            update(singleBookingRef(bookingKey), {
                                                status: 'CANCELLED', driver_accepted: null, reason: 'Không có chuyến gần đây', cancel_time: GetCurrentMiniTimeStamp(), notify_to_center: msg, total_cancel: data.total_cancel ? data.total_cancel + 1 : 1
                                            });
                                            if (data.callId) {
                                                update(paCallByIdRef(data.callId), {status: "booked_cancel", total_cancel: data.total_cancel ? data.total_cancel + 1 : 1});
                                            }

                                            if (data.reason_type && data.reason_type == 'rider') {
                                            } else {
                                                if (data.type == 'LX' && data.customer) {
                                                    if (data.type == 'LX' && data.customer) {
                                                        onValue(notifyImportanceToRef(data.customer), (snapshot) => {
                                                            if (snapshot.val()) {
                                                                let dataNotify = snapshot.val();
                                                                const arr = Object.keys(dataNotify)
                                                                    .filter(i => dataNotify[i].type == "booking_request" && dataNotify[i].booking_id && dataNotify[i].booking_id == bookingKey)
                                                                    .map(i => {
                                                                        dataNotify[i].id = i;
                                                                        return dataNotify[i];
                                                                    });

                                                                if (arr && arr.length > 0) {
                                                                    let notify = arr[0];
                                                                    let dataNotify = {
                                                                        title: "Cuốc khách không tìm thấy lái xe", content: "Có vẻ các tài xế đang bận, vui lòng đặt lại sau ít phút",
                                                                    };
                                                                    update(notifyImportanceIdRef(notify.id), dataNotify);
                                                                }
                                                            }
                                                        }, {onlyOnce: true});
                                                    }
                                                }

                                            }
                                            dispatch({
                                                type: SELECT_BOOKING_DETAIL_ON_SUCCESS, bookingOn: data
                                            });
                                        }
                                    });
                                } else {
                                    console.log("Huỷ luôn");
                                    if (data.customer_token) {
                                        RequestPushMsg(data.customer_token, language.notification_title, "Yêu cầu đặt xe tự động hủy do các tài xế đang bận!");
                                    }
                                    let msg = ConvertTimeStampToDate(GetCurrentMiniTimeStamp()) + ' Cuốc xe đã tự động hủy 0 do không có lái xe phù hợp #' + bookingKey;
                                    push(reportdriverRef, {content: msg});
                                    set(countTimeRef(bookingKey), "KHONG_BAO_TIN_HIEU");
                                    update(singleBookingRef(bookingKey), {
                                        status: 'CANCELLED', driver_accepted: null, reason: 'Không có chuyến gần đây', cancel_time: GetCurrentMiniTimeStamp(), notify_to_center: msg, total_cancel: data.total_cancel ? data.total_cancel + 1 : 1
                                    });
                                    if (data.callId) {
                                        update(paCallByIdRef(data.callId), {status: "booked_cancel", total_cancel: data.total_cancel ? data.total_cancel + 1 : 1});
                                    }

                                    if (data.type == 'LX' && data.customer) {
                                        if (data.reason_type && data.reason_type == 'rider') {
                                        } else {
                                            if (data.type == 'LX' && data.customer) {
                                                if (data.type == 'LX' && data.customer) {
                                                    onValue(notifyImportanceToRef(data.customer), (snapshot) => {
                                                        if (snapshot.val()) {
                                                            let dataNotify = snapshot.val();
                                                            const arr = Object.keys(dataNotify)
                                                                .filter(i => dataNotify[i].type == "booking_request" && dataNotify[i].booking_id && dataNotify[i].booking_id == bookingKey)
                                                                .map(i => {
                                                                    dataNotify[i].id = i;
                                                                    return dataNotify[i];
                                                                });

                                                            if (arr && arr.length > 0) {
                                                                let notify = arr[0];
                                                                let dataNotify = {
                                                                    title: "Cuốc khách không tìm thấy lái xe", content: "Có vẻ các tài xế đang bận, vui lòng đặt lại sau ít phút",
                                                                };
                                                                update(notifyImportanceIdRef(notify.id), dataNotify);
                                                            }
                                                        }
                                                    }, {onlyOnce: true});
                                                }
                                            }
                                        }
                                    }
                                }
                                dispatch({
                                    type: SELECT_BOOKING_DETAIL_ON_SUCCESS, bookingOn: data
                                });
                            })
                            .catch((e) => {
                                if ((data.reason_type && data.reason_type == 'rider') || data.status == "CANCELLED") {
                                } else {
                                    let msg = ConvertTimeStampToDate(GetCurrentMiniTimeStamp()) + ' Cuốc xe đã tự động hủy 0 do không có lái xe phù hợp #' + bookingKey;
                                    push(reportdriverRef, {content: msg});
                                    set(countTimeRef(bookingKey), "KHONG_BAO_TIN_HIEU");
                                    update(singleBookingRef(bookingKey), {
                                        status: 'CANCELLED', driver_accepted: null, reason: 'Không có chuyến gần đây', cancel_time: GetCurrentMiniTimeStamp(), notify_to_center: msg, total_cancel: data.total_cancel ? data.total_cancel + 1 : 1
                                    });
                                    if (data.callId) {
                                        update(paCallByIdRef(data.callId), {status: "booked_cancel", total_cancel: data.total_cancel ? data.total_cancel + 1 : 1});
                                    }

                                    if (data.type == 'LX' && data.customer) {
                                        if (data.reason_type && data.reason_type == 'rider') {
                                        } else {
                                            onValue(notifyImportanceToRef(data.customer), (snapshot) => {
                                                if (snapshot.val()) {
                                                    let dataNotify = snapshot.val();
                                                    const arr = Object.keys(dataNotify)
                                                        .filter(i => dataNotify[i].type == "booking_request" && dataNotify[i].booking_id && dataNotify[i].booking_id == bookingKey)
                                                        .map(i => {
                                                            dataNotify[i].id = i;
                                                            return dataNotify[i];
                                                        });

                                                    if (arr && arr.length > 0) {
                                                        let notify = arr[0];
                                                        let dataNotify = {
                                                            title: "Cuốc khách không tìm thấy lái xe", content: "Có vẻ các tài xế đang bận, vui lòng đặt lại sau ít phút",
                                                        };
                                                        update(notifyImportanceIdRef(notify.id), dataNotify);
                                                    }
                                                }
                                            }, {onlyOnce: true});
                                        }

                                    }
                                }
                                dispatch({
                                    type: SELECT_BOOKING_DETAIL_ON_SUCCESS, bookingOn: data
                                });
                            });
                    }
                }
            }
        } else {
            dispatch({
                type: SELECT_BOOKING_DETAIL_ON_FAILED, countTime: "No booking available."
            });
        }
    }, {onlyOnce: true});
}

export const getBookingByDriver = (uid) => (dispatch) => {
    const {
        bookingRef
    } = firebase;

    dispatch({
        type: SELECT_BOOKING_DRIVER, booking_uid: null
    });

    onValue(query(bookingRef, orderByChild("driver"), equalTo(uid)), snapshot => {
        var tracked = null;
        if (snapshot.val()) {
            const data = snapshot.val();
            const bookings = Object.keys(data)
                .map((i) => {
                    data[i].id = i;
                    data[i].bookingDate = data[i].tripdate ? data[i].tripdate : null;
                    if (data[i].pickup) {
                        data[i].pickupAddress = data[i].pickup.add;
                    }
                    if (data[i].drop) {
                        data[i].dropAddress = data[i].drop.add;
                    }
                    data[i].discount = data[i].discount_amount ? data[i].discount_amount : 0;
                    data[i].cashPaymentAmount = data[i].cashPaymentAmount ? data[i].cashPaymentAmount : 0;
                    data[i].cardPaymentAmount = data[i].cardPaymentAmount ? data[i].cardPaymentAmount : 0;
                    return data[i];
                });
            for (let i = 0; i < bookings.length; i++) {
                if (bookings[i].driver && (['ACCEPTED', 'ARRIVED', 'STARTED', 'REACHED', 'DA_DEN_CHO_DON_KHACH'].indexOf(bookings[i].status) != -1)) {
                    tracked = bookings[i];
                }
            }
            dispatch({
                type: SELECT_BOOKING_DRIVER_SUCCESS, booking_uid: tracked
            });
        } else {
            dispatch({
                type: SELECT_BOOKING_DRIVER_FAILED, booking_uid: "No booking available."
            });
        }
    }, {onlyOnce: true});

}

/*
export const addBooking = (bookingData) => (dispatch) => {
    const {
        bookingRef
    } = firebase;

    dispatch({
        type: CONFIRM_BOOKING, payload: bookingData,
    });
    let pickUp = {
        lat: bookingData.pickup.coords.lat, lng: bookingData.pickup.coords.lng, add: bookingData.pickup.description
    };
    let drop = {lat: bookingData.drop.coords.lat, lng: bookingData.drop.coords.lng, add: bookingData.drop.description};
    var otp = false;

    if (bookingData.settings.otp_secure) otp = Math.floor(Math.random() * 90000) + 10000;

    let today = new Date();

    var data = {
        carType: bookingData.carDetails && bookingData.carDetails.name ? bookingData.carDetails.name : null,
        carImage: bookingData.carDetails && bookingData.carDetails.image ? bookingData.carDetails.image : null,
        customer: bookingData.userDetails.uid,
        customer_name: bookingData.userDetails.profile.firstName + ' ' + bookingData.userDetails.profile.lastName,
        customer_contact: bookingData.userDetails.profile.mobile ? bookingData.userDetails.profile.mobile : '',
        customer_note: bookingData.userDetails.profile.note ? bookingData.userDetails.profile.note : '',
        customer_token: bookingData.userDetails.profile.pushToken,
        customer_image: bookingData.userDetails.profile.profile_image ? bookingData.userDetails.profile.profile_image : "",
        drop: drop,
        pickup: pickUp,
        estimate: bookingData.estimate.estimateFare,
        estimateDistance: bookingData.estimate.estimateDistance,
        estimateTime: bookingData.estimate.estimateTime,
        status: "NEW",
        bookLater: bookingData.bookLater ? bookingData.bookLater : false,
        tripdate: bookingData.bookLater ? bookingData.tripdate : today.getTime(),
        bookingDate: today.getTime(),
        otp: otp,
        booking_type_web: bookingData.booking_type_web,
        coords: bookingData.estimate.waypoints,
        driver: bookingData.driverDetails && bookingData.driverDetails.uid ? bookingData.driverDetails.uid : null,
        driverRating: bookingData.driverDetails && bookingData.driverDetails.driverRating ? bookingData.driverDetails.driverRating : null,
        driver_name: bookingData.driverDetails && bookingData.driverDetails.driver_name ? bookingData.driverDetails.driver_name : null,
        driver_contact: bookingData.driverDetails && bookingData.driverDetails.driver_contact ? bookingData.driverDetails.driver_contact : null,
        codeDriver: bookingData.driverDetails && bookingData.driverDetails.codeDriver ? bookingData.driverDetails.codeDriver : null,
        driver_token: bookingData.driverDetails && bookingData.driverDetails.driver_token ? bookingData.driverDetails.driver_token : null,
        driver_image: bookingData.driverDetails && bookingData.driverDetails.driver_image ? bookingData.driverDetails.driver_image : null,
        customer_wish: false
    }

    bookingRef.push(data).then((res) => {
        var bookingKey = res.key;
        dispatch({
            type: CONFIRM_BOOKING_SUCCESS, payload: {
                booking_id: bookingKey, mainData: data,
            }
        });
    }).catch(error => {
        dispatch({
            type: CONFIRM_BOOKING_FAILED, payload: error.code + ": " + error.message,
        });
    });
};
*/

export const addBookingV2 = (bookingData, isUpdate = false) => async (dispatch) => {
    const {
        refApi,
        bookingRef, requestedDriversRef, countTimeRef, //countTimeStampRef,
        notifyImportanceToRef, reportdriverRef, singleBookingRef, paCallByIdRef, userByCodeRef, singleUserRef, usersRef, notifyImportanceIdRef
    } = firebase;

    dispatch({
        type: CONFIRM_BOOKING, payload: bookingData,
    });

    let settings = store.getState().settingsdata.settings;
    let groups = store.getState().groupdriverdata.complex;
    let estimatedata = store.getState().tripdata.trip_estimate;
    let cars = store.getState().cartypes.cars;
    let carType = bookingData.carType;
    var carSelect = null;
    if (cars && cars.length > 0) {
        carSelect = cars.filter((car) => {
            if (car.name === carType) {
                return true;
            }
        })[0];
    }
    var groupKd3Km = groups.filter(gp => parseInt(gp.value) === 11)[0];
    var pickUp = null;
    var drop = null;
    if (bookingData.pickup && bookingData.pickup.add) {
        if (bookingData.pickup.coords && bookingData.pickup.coords.lat) {
            pickUp = {
                add: bookingData.pickup.add, lat: bookingData.pickup.coords.lat, lng: bookingData.pickup.coords.lng
            };
        }
        if (bookingData.pickup.lat) {
            pickUp = {
                add: bookingData.pickup.add, lat: bookingData.pickup.lat, lng: bookingData.pickup.lng
            };
        }
    }

    if (bookingData.drop && bookingData.drop.add) {
        if (bookingData.drop.coords && bookingData.drop.coords.lat) {
            drop = {
                add: bookingData.drop.add, lat: bookingData.drop.coords.lat, lng: bookingData.drop.coords.lng
            };
        }
        if (bookingData.drop.lat) {
            drop = {
                add: bookingData.drop.add, lat: bookingData.drop.lat, lng: bookingData.drop.lng
            };
        }
    }

    let phoneSearch1 = '+84' + bookingData.customer_contact;
    let phoneSearch2 = phoneSearch1.replace("+840", "+84");
    let userByPhone = onValue(query(usersRef, orderByChild("mobile"), equalTo(phoneSearch1)), snapshot => {
        if (snapshot.val()) {
            const data = snapshot.val();
            const arr = Object.keys(data)
                .filter(i => data[i] && data[i].usertype && data[i].usertype != 'admin')
                .map(i => {
                    data[i].id = i;
                    return data[i];
                });
            return arr[0];
        } else {
            onValue(query(usersRef, orderByChild("mobile"), equalTo(phoneSearch2)), snapshot => {
                if (snapshot.val()) {
                    const data = snapshot.val();
                    const arr = Object.keys(data)
                        .filter(i => data[i] && data[i].usertype && data[i].usertype != 'admin')
                        .map(i => {
                            data[i].id = i;
                            return data[i];
                        });
                    return arr[0];
                } else {
                    return null;
                }
            }, {onlyOnce: true});
        }
    });

    let bookingPushInList = {};
    let bookingPushInAreaList = {};
    let bookingPushOutList = {};
    let arrIn = [];
    let arrInArea = [];
    let arrOut = [];
    let arrPushIn = [];
    let arrPushInArea = [];
    let arrPushOut = [];
    let objInMsg = {
        "to": null, "title": language.notification_title, "msg": "Có khách hàng mới",
    };
    let objOutMsg = {
        "to": null, "title": language.notification_title, "msg": "Có khách hàng mới",
    };
    if (bookingData.driverInGroup && bookingData.driverInGroup.length > 0) {
        await bookingData.driverInGroup.map((driver) => {
            if (bookingData.driverBook && bookingData.driverBook.code) {
                if (driver.codeDriver.toString().trim() !== bookingData.driverBook.code.toString().trim()) {
                    let usr = driver;
                    if (usr.pushToken) {
                        objInMsg.to = usr.pushToken;
                        objInMsg.id = driver.id;
                        arrIn.push(objInMsg);
                        arrPushIn.push(usr.codeDriver.toString().trim());
                    }
                    Object.assign(bookingPushInList, {[driver.id]: true});
                }
            } else {
                let usr = driver;
                if (usr.pushToken) {
                    objInMsg.to = usr.pushToken;
                    objInMsg.id = driver.id;
                    arrIn.push(objInMsg);
                    arrPushIn.push(usr.codeDriver.toString().trim());
                }
                Object.assign(bookingPushInList, {[driver.id]: true});
            }
        });
    }

    if (bookingData.driverOutGroup && bookingData.driverOutGroup.length > 0) {
        await bookingData.driverOutGroup.map((driver) => {
            if (bookingData.driverBook && bookingData.driverBook.code) {
                if (driver.codeDriver.toString().trim() !== bookingData.driverBook.code.toString().trim()) {
                    let usr = driver;
                    if (usr.pushToken) {
                        objOutMsg.to = usr.pushToken;
                        objOutMsg.id = driver.id;
                        arrOut.push(objOutMsg);
                        arrPushOut.push(usr.codeDriver.toString().trim());
                    }
                    Object.assign(bookingPushOutList, {[driver.id]: true});
                }
            } else {
                let usr = driver;
                if (usr.pushToken) {
                    objOutMsg.to = usr.pushToken;
                    objOutMsg.id = driver.id;
                    arrOut.push(objOutMsg);
                    arrPushOut.push(usr.codeDriver.toString().trim());
                }
                Object.assign(bookingPushOutList, {[driver.id]: true});
            }
        });
    }

    if (bookingData.driverInArea && bookingData.driverInArea.length > 0) {
        await bookingData.driverInArea.map((driver) => {
            if (bookingData.driverBook && bookingData.driverBook.code) {
                if (driver.codeDriver.toString().trim() !== bookingData.driverBook.code.toString().trim()) {
                    let usr = driver;
                    if (usr.pushToken) {
                        objOutMsg.to = usr.pushToken;
                        objOutMsg.id = driver.id;
                        arrInArea.push(objOutMsg);
                        arrPushInArea.push(usr.codeDriver.toString().trim());
                    }
                    Object.assign(bookingPushInAreaList, {[driver.id]: true});
                }
            } else {
                let usr = driver;
                if (usr.pushToken) {
                    objOutMsg.to = usr.pushToken;
                    objOutMsg.id = driver.id;
                    arrInArea.push(objOutMsg);
                    arrPushInArea.push(usr.codeDriver.toString().trim());
                }
                Object.assign(bookingPushInAreaList, {[driver.id]: true});
            }
        });
    }

    var estimateTemp = bookingData.estimateTemp ? bookingData.estimateTemp : null;
    if (drop && drop.add && pickUp && pickUp.add && estimateTemp == null) {
        let startLoc = pickUp.lat + ',' + pickUp.lng;
        let destLoc = drop.lat + ',' + drop.lng;
        var distanceEstimate2 = GetDistance(pickUp.lat, pickUp.lng, drop.lat, drop.lng);
        var timeEstimate2 = (distanceEstimate2 / 35) * 60;
        try {
            let arriveTime2 = await getDriveTime(startLoc, destLoc);
            if (arriveTime2 && arriveTime2.distance_in_km && arriveTime2.time_in_secs) {
                estimateTemp = arriveTime2;
            } else {
                if (distanceEstimate2 && timeEstimate2) {
                    estimateTemp.distance_in_km = distanceEstimate2 ? parseFloat(distanceEstimate2) : 0;
                    estimateTemp.time_in_secs = timeEstimate2;
                }
            }
        } catch (e) {
            if (distanceEstimate2 && timeEstimate2) {
                estimateTemp.distance_in_km = distanceEstimate2 ? parseFloat(distanceEstimate2) : 0;
                estimateTemp.time_in_secs = timeEstimate2;
            }
        }
    }
    if (!isUpdate) {
        var data = prepareDataBeforAddBookingV2(bookingData, carSelect, pickUp, drop, estimatedata, groupKd3Km, bookingPushOutList, bookingPushInList, bookingPushInAreaList, userByPhone);
    } else {
        data = bookingData;
    }


    if (arrPushOut && arrPushOut.length > 0) {
        data.pushed_drivers = arrPushOut.join(",");
    }
    if (arrPushIn && arrPushIn.length > 0) {
        data.pushed_in_drivers = arrPushIn.join(",");
    }

    if (data.estimateTemp) {
        data.isMetter = false;
    } else {
        data.isMetter = true;
    }
    data.vipStatus = false;
    data.vipPlus = false;
    data.count_time = 1;
    data.requestedDrivers = data.bookingPushOutList ? data.bookingPushOutList : bookingPushOutList;
    if (isUpdate && data.id) {
        data.status = "NEWV2";
        await update(singleBookingRef(data.id), data).then(async (res) => {
            let bookingKey = data.id;
            dispatch({
                type: UPDATE_SESSION_BOOKING_ID, payload: bookingKey,
            });
            data.id = bookingKey;
            if (data.callId) {
                if (data.old_booking_id) {
                    await update(paCallByIdRef(data.callId), {booking_id: bookingKey, status: "booked", booking_time: GetCurrentMiniTimeStamp(), count_mistake: null, old_booking_id: data.old_booking_id, booking_note: data.customer_note, note: data.customer_note});
                } else {
                    await update(paCallByIdRef(data.callId), {booking_id: bookingKey, status: "booked", booking_time: GetCurrentMiniTimeStamp(), count_mistake: null, booking_note: data.customer_note, note: data.customer_note});
                }
            }
            let localString = ConvertTimeStampToDate(GetCurrentMiniTimeStamp());
            if (data.status === 'NEWV2') {
                if (data.type == 'LX' && data.customer) {
                    let dataNotify = {
                        date_add: GetCurrentTimeStamp(), status: 'pending', from: data.customer, type: 'booking_request', booking_id: bookingKey, title: "Bạn đã đặt cuốc khách thành công", content: "Vui lòng đợi trong giây lát để tài xế nhận cuốc", to: data.customer, params: [data]
                    };
                    dispatch(pushNotifyImportance(dataNotify));
                }
            }
            let list = [];
            if (data.status === 'BOOKING_LATER') {
                data.id = bookingKey;
                if (data.driverOutGroup) {
                    let listDriver = Object.values(data.driverOutGroup);
                    for (let y = 0; y < listDriver.length; y++) {
                        let dataNotify = {
                            date_add: GetCurrentTimeStamp(), status: 'pending', booking_id: bookingKey, from: data.customer, type: 'booking_later', title: "Khách hàng đặt trước", content: "Bạn có khách hàng đặt trước vào lúc " + ConvertTimeStampToDate(data.bookingDate), to: listDriver[y].id, params: data
                        };
                        dispatch(pushNotifyImportance(dataNotify));
                    }
                }
            }
            dispatch({
                type: CONFIRM_BOOKING_SUCCESS, payload: {
                    booking_id: bookingKey, mainData: data
                }
            });
        }).catch(error => {
            dispatch({
                type: CONFIRM_BOOKING_FAILED, payload: error.code + ": " + error.message,
            });
        });
    } else {
        console.log("3123123123123");
        await push(bookingRef, data).then(async (res) => {
            let bookingKey = res.key;
            dispatch({
                type: UPDATE_SESSION_BOOKING_ID, payload: bookingKey,
            });
            data.id = bookingKey;
            if (data.callId) {
                if (data.old_booking_id) {
                    if (data.status === 'BOOKING_LATER') {
                        await update(paCallByIdRef(data.callId), {booking_id: bookingKey, status: "booking_later", booking_time: GetCurrentMiniTimeStamp(), booking_schedule: data.bookingDate, count_mistake: null, old_booking_id: data.old_booking_id, booking_note: data.customer_note, note: data.customer_note});
                    } else {
                        await update(paCallByIdRef(data.callId), {booking_id: bookingKey, status: "booked", booking_time: GetCurrentMiniTimeStamp(), count_mistake: null, old_booking_id: data.old_booking_id, booking_note: data.customer_note, note: data.customer_note});
                    }
                } else {
                    if (data.status === 'BOOKING_LATER') {
                        await update(paCallByIdRef(data.callId), {booking_id: bookingKey, status: "booking_later", booking_schedule: data.bookingDate, booking_time: GetCurrentMiniTimeStamp(), count_mistake: null, booking_note: data.customer_note, note: data.customer_note});
                    } else {
                        await update(paCallByIdRef(data.callId), {booking_id: bookingKey, status: "booked", booking_time: GetCurrentMiniTimeStamp(), count_mistake: null, booking_note: data.customer_note, note: data.customer_note});
                    }

                }
            }
            let localString = ConvertTimeStampToDate(GetCurrentMiniTimeStamp());
            if (data.status === 'NEWV2') {
                if (data.type == 'LX' && data.customer) {
                    let dataNotify = {
                        date_add: GetCurrentTimeStamp(), status: 'pending', from: data.customer, type: 'booking_request', booking_id: bookingKey, title: "Bạn đã đặt cuốc khách thành công", content: "Vui lòng đợi trong giây lát để tài xế nhận cuốc", to: data.customer, params: [data]
                    };
                    dispatch(pushNotifyImportance(dataNotify));
                }
            }
            let list = [];
            if (data.status === 'BOOKING_LATER') {
                data.id = bookingKey;
                if (data.driverOutGroup) {
                    let listDriver = Object.values(data.driverOutGroup);
                    for (let y = 0; y < listDriver.length; y++) {
                        let dataNotify = {
                            date_add: GetCurrentTimeStamp(), status: 'pending', booking_id: bookingKey, from: data.customer, type: 'booking_later', title: "Khách hàng đặt trước", content: "Bạn có khách hàng đặt trước vào lúc " + ConvertTimeStampToDate(data.bookingDate), to: listDriver[y].id, params: data
                        };
                        dispatch(pushNotifyImportance(dataNotify));
                    }
                }
            }
            dispatch({
                type: CONFIRM_BOOKING_SUCCESS, payload: {
                    booking_id: bookingKey, mainData: data
                }
            });
        }).catch(error => {
            dispatch({
                type: CONFIRM_BOOKING_FAILED, payload: error.code + ": " + error.message,
            });
        });
    }

};


export const addBookingV3 = (bookingData) => async (dispatch) => {
    const {
        bookingRef, usersRef
    } = firebase;

    dispatch({
        type: CONFIRM_BOOKING, payload: bookingData,
    });

    let groups = store.getState().groupdriverdata.complex;
    let estimatedata = store.getState().tripdata.trip_estimate;
    var tripEstimate = null
    let cars = store.getState().cartypes.cars;
    let carType = bookingData.carType;
    var carSelect = null;
    if (cars && cars.length > 0) {
        carSelect = cars.filter((car) => {
            if (car.name === carType) {
                return true;
            }
        })[0];
    }

    var groupKd3Km = groups.filter(gp => parseInt(gp.value) === 11)[0];
    var pickUp = null;
    var drop = null;
    if (bookingData.pickup && bookingData.pickup.add) {
        if (bookingData.pickup.coords && bookingData.pickup.coords.lat) {
            pickUp = {
                add: bookingData.pickup.add, lat: bookingData.pickup.coords.lat, lng: bookingData.pickup.coords.lng
            };
        }
        if (bookingData.pickup.lat) {
            pickUp = {
                add: bookingData.pickup.add, lat: bookingData.pickup.lat, lng: bookingData.pickup.lng
            };
        }
    }

    if (bookingData.drop && bookingData.drop.add) {
        if (bookingData.drop.coords && bookingData.drop.coords.lat) {
            drop = {
                add: bookingData.drop.add, lat: bookingData.drop.coords.lat, lng: bookingData.drop.coords.lng
            };
        }
        if (bookingData.drop.lat) {
            drop = {
                add: bookingData.drop.add, lat: bookingData.drop.lat, lng: bookingData.drop.lng
            };
        }
    }

    let phoneSearch1 = '+84' + bookingData.customer_contact;
    let phoneSearch2 = phoneSearch1.replace("+840", "+84");
    let userByPhone = onValue(query(usersRef, orderByChild("mobile"), equalTo(phoneSearch1)), snapshot => {
        if (snapshot.val()) {
            const data = snapshot.val();
            const arr = Object.keys(data)
                .filter(i => data[i] && data[i].usertype && data[i].usertype != 'admin')
                .map(i => {
                    data[i].id = i;
                    return data[i];
                });
            return arr[0];
        } else {
            onValue(query(usersRef, orderByChild("mobile"), equalTo(phoneSearch2)), snapshot => {
                if (snapshot.val()) {
                    const data = snapshot.val();
                    const arr = Object.keys(data)
                        .filter(i => data[i] && data[i].usertype && data[i].usertype != 'admin')
                        .map(i => {
                            data[i].id = i;
                            return data[i];
                        });
                    return arr[0];
                } else {
                    return null;
                }
            }, {onlyOnce: true});
        }
    });

    let bookingPushInList = {};
    let bookingPushInAreaList = {};
    let bookingPushOutList = {};
    let objInMsg = {
        "to": null, "title": language.notification_title, "msg": "Có khách hàng mới",
    };
    let arrOut = [];
    let arrPushIn = [];
    let arrInArea = [];
    let arrPushInArea = [];
    let arrIn = [];
    let arrPushOut = [];
    let objOutMsg = {
        "to": null, "title": language.notification_title, "msg": "Có khách hàng mới",
    };
    if (bookingData.driverInGroup && bookingData.driverInGroup.length > 0) {
        await bookingData.driverInGroup.map((driver) => {
            if (bookingData.driverBook && bookingData.driverBook.code) {
                if (driver.codeDriver.toString().trim() !== bookingData.driverBook.code.toString().trim()) {
                    let usr = driver;
                    if (usr.pushToken) {
                        objInMsg.to = usr.pushToken;
                        objInMsg.id = driver.id;
                        arrIn.push(objInMsg);
                        arrPushIn.push(usr.codeDriver.toString().trim());
                    }
                    Object.assign(bookingPushInList, {[driver.id]: true});
                }
            } else {
                let usr = driver;
                if (usr.pushToken) {
                    objInMsg.to = usr.pushToken;
                    objInMsg.id = driver.id;
                    arrIn.push(objInMsg);
                    arrPushIn.push(usr.codeDriver.toString().trim());
                }
                Object.assign(bookingPushInList, {[driver.id]: true});
            }
        });
    }

    if (bookingData.driverOutGroup && bookingData.driverOutGroup.length > 0) {
        await bookingData.driverOutGroup.map((driver) => {
            if (bookingData.driverBook && bookingData.driverBook.code) {
                if (driver.codeDriver.toString().trim() !== bookingData.driverBook.code.toString().trim()) {
                    let usr = driver;
                    if (usr.pushToken) {
                        objOutMsg.to = usr.pushToken;
                        objOutMsg.id = driver.id;
                        arrOut.push(objOutMsg);
                        arrPushOut.push(usr.codeDriver.toString().trim());
                    }
                    Object.assign(bookingPushOutList, {[driver.id]: true});
                }
            } else {
                let usr = driver;
                if (usr.pushToken) {
                    objOutMsg.to = usr.pushToken;
                    objOutMsg.id = driver.id;
                    arrOut.push(objOutMsg);
                    arrPushOut.push(usr.codeDriver.toString().trim());
                }
                Object.assign(bookingPushOutList, {[driver.id]: true});
            }
        });
    }

    if (bookingData.driverInArea && bookingData.driverInArea.length > 0) {
        await bookingData.driverInArea.map((driver) => {
            if (bookingData.driverBook && bookingData.driverBook.code) {
                if (driver.codeDriver.toString().trim() !== bookingData.driverBook.code.toString().trim()) {
                    let usr = driver;
                    if (usr.pushToken) {
                        objOutMsg.to = usr.pushToken;
                        objOutMsg.id = driver.id;
                        arrInArea.push(objOutMsg);
                        arrPushInArea.push(usr.codeDriver.toString().trim());
                    }
                    Object.assign(bookingPushInAreaList, {[driver.id]: true});
                }
            } else {
                let usr = driver;
                if (usr.pushToken) {
                    objOutMsg.to = usr.pushToken;
                    objOutMsg.id = driver.id;
                    arrInArea.push(objOutMsg);
                    arrPushInArea.push(usr.codeDriver.toString().trim());
                }
                Object.assign(bookingPushInAreaList, {[driver.id]: true});
            }
        });
    }


    var estimateTemp = bookingData.estimateTemp ? bookingData.estimateTemp : null;
    if (drop && drop.add && pickUp && pickUp.add && estimateTemp == null) {
        let startLoc = pickUp.lat + ',' + pickUp.lng;
        let destLoc = drop.lat + ',' + drop.lng;
        var distanceEstimate2 = await GetDistance(pickUp.lat, pickUp.lng, drop.lat, drop.lng);
        var timeEstimate2 = (distanceEstimate2 / 35) * 60;
        try {
            let arriveTime2 = await getDriveTime(startLoc, destLoc);
            if (arriveTime2 && arriveTime2.distance_in_km && arriveTime2.time_in_secs) {
                estimateTemp = arriveTime2;
            } else {
                if (distanceEstimate2 && timeEstimate2) {
                    estimateTemp.distance_in_km = distanceEstimate2 ? parseFloat(distanceEstimate2) : 0;
                    estimateTemp.time_in_secs = timeEstimate2;
                }
            }
        } catch (e) {
            if (distanceEstimate2 && timeEstimate2) {
                estimateTemp.distance_in_km = distanceEstimate2 ? parseFloat(distanceEstimate2) : 0;
                estimateTemp.time_in_secs = timeEstimate2;
            }
        }
    }


    var data = prepareDataBeforAddBookingV2(bookingData, carSelect, pickUp, drop, estimateTemp, groupKd3Km, bookingPushOutList, bookingPushInList, bookingPushInAreaList, userByPhone);
    if (arrPushOut && arrPushOut.length > 0) {
        data.pushed_drivers = arrPushOut.join(",");
    }
    if (arrPushIn && arrPushIn.length > 0) {
        data.pushed_in_drivers = arrPushIn.join(",");
    }

    if (data.estimateTemp) {
        data.isMetter = false;
    } else {
        data.isMetter = true;
    }
    data.vipStatus = false;
    data.vipPlus = false;

    if (arrInArea && arrInArea.length > 0) {
        data.requestedDrivers = bookingPushInAreaList;
    } else {
        if (arrOut && arrOut.length > 0) {
            data.requestedDrivers = bookingPushOutList;
        }
    }

    data.count_time = 1;
    data.reason = 'Vui lòng chờ trong giây lát để tài xế nhận cuốc';

    let bookingId = await push(bookingRef, data).then(async (res) => {
        let bookingKey = res.key;
        data.id = bookingKey;
        if (data.type == 'LX' && data.customer) {
            let dataNotify = {
                date_add: GetCurrentTimeStamp(), status: 'pending', from: data.customer, type: 'booking_request', booking_id: bookingKey, title: "Bạn đã đặt cuốc khách thành công", content: "Vui lòng đợi trong giây lát để tài xế nhận cuốc", to: data.customer, params: [data]
            };
            dispatch(pushNotifyImportance(dataNotify));
            dispatch({
                type: FETCH_NEWS_BOOKINGS_SUCCESS,
                payload: {
                    booking_news: [data]
                },
            });
        }
        return bookingKey;
    }).catch(error => {
        dispatch({
            type: CONFIRM_BOOKING_FAILED, payload: error.code + ": " + error.message,
        });
        return null;
    });
    return bookingId;
};

export const loadBookingByTripCode = (tripCode) => (dispatch) => {
    const {
        singleBookingByCodeRef,
    } = firebase;

    dispatch({
        type: FETCH_BOOKING_DETAIL_BY_CODE, payload: null,
    });
    if (tripCode) {
        onValue(singleBookingByCodeRef(tripCode), snapshot => {
            if (snapshot.val()) {
                dispatch({
                    type: FETCH_BOOKING_DETAIL_BY_CODE_SUCCESS, payload: snapshot.val()
                });
            } else {
                dispatch({
                    type: FETCH_BOOKING_DETAIL_BY_CODE_FAILED, payload: "Không tìm cuốc khách #" + tripCode,
                });
            }
        });
    } else {
        dispatch({
            type: FETCH_BOOKING_DETAIL_BY_CODE_FAILED, payload: "Thiếu mã cuốc khách"
        });
    }
}

export const stopLoadBookingByTripCode = (tripCode) => (dispatch) => {
    const {
        singleBookingByCodeRef
    } = firebase;
    dispatch({
        type: FETCH_BOOKING_DETAIL_BY_CODE_STOP, payload: tripCode
    });
    off(singleBookingByCodeRef(tripCode));
}

export const removeBookingSignals = (bookingKey) => (dispatch) => {
    const {
        requestedDriversRef,
    } = firebase;
    remove(requestedDriversRef(bookingKey));
}
export const updateBookingApi = async (bookingId, data) => {
    const {
        singleBookingRef,
    } = firebase;
    await update(singleBookingRef(bookingId), data);
    console.log("updateSuccess");
}

export const updateBookingReachedApi = async (booking) => {
    let bookingObj = await addActualsToBooking(booking);
    return bookingObj;
}
